import { createContext, useEffect, useState } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { CompanyEmployees } from './companyEmployees/companyEmployees'
import { CompanyCards } from './companyCards/companyCards'
import { PageHeader, Panel } from '@/presentation/components/molecules'
import { Box, Button, Icon, StatusChip, Tab, TabPanel, Tabs, Tooltip } from '@/presentation/components/atoms'
import { CompanyGeneralData } from './companyGeneralData/companyGeneralData'
import { useDocumentTitle } from '@/main/hooks'
import { COMPANY_STATUS } from '@/main/utils/constants'
import { MigrationErrorDialog } from './components/migrationErrorDialog'
import { CompanyMigrationErrorPersistentStore } from '@/main/store/companyMigrationError'
import { CompanyBenefits } from './companyBenefits/companyBenefits'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'
import { useWarning } from './hooks/useWarning'
import { Parameters } from './components/parameters/parameters'
import { IUseCompanyReturn, useCompany } from './hooks/useCompany'

export const companyTab = atomWithHash('companyTab', 0)

export const CompanyContext = createContext<IUseCompanyReturn>({} as IUseCompanyReturn)

export const ConsultCompany = () => {
  const [value, setValue] = useAtom(companyTab)
  const records = useAtomValue(CompanyMigrationErrorPersistentStore)
  const [isMigrationDialogOpen, setIsMigrationDialogOpen] = useState<boolean>(false)
  const documentTile = useDocumentTitle()

  const { resources } = useAccessProfile({ resource: 'empresa' })
  useWarning({ value })

  const { company, isFetching, isLoading } = useCompany({
    config: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  })

  const { tradeName = 'Empresa', code = '', status, id, migrationId, messageMigration } = company || {}

  documentTile.change({ title: tradeName })
  const tabName = [
    `${tradeName} ${code && ' - ' + code}`,
    `Parâmetros - ${tradeName}`,
    `Colaboradores - ${tradeName}`,
    `Cartões - ${tradeName}`,
    `Benefícios - ${tradeName}`,
  ]

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  let statusColor = 'warning'
  if ([COMPANY_STATUS.APPROVED].includes(status)) statusColor = 'success'
  else if ([COMPANY_STATUS.DISAPPROVED, COMPANY_STATUS.MIGRATION_ERROR, COMPANY_STATUS.SYNC_ERROR].includes(status))
    statusColor = 'error'

  useEffect(() => {
    const record = records[id]
    if (!record && migrationId) setIsMigrationDialogOpen(true)
    else if (record && migrationId && record.migrationId !== migrationId) setIsMigrationDialogOpen(true)
    else setIsMigrationDialogOpen(false)
  }, [migrationId, id, records])

  const closeMigrationErrorDialog = () => setIsMigrationDialogOpen(false)

  return (
    <Panel>
      <CompanyContext.Provider
        value={{
          company,
          isFetching,
          isLoading,
        }}
      >
        <PageHeader pageTitle={tabName[value]} />
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            asGroupButtons
            sx={{
              padding: '4px 0 0 16px',
              '& .MuiTabs-flexContainer': { alignItems: 'center' },
            }}
          >
            {resources({ item: 'empresa_visualizar' }) ? <Tab label='Dados Gerais' tabIndex={0} value={0} /> : null}
            {resources({ item: 'empresa_parametros_gerais' }) ? (
              <Tab label='Parâmetros' tabIndex={1} value={1} />
            ) : null}
            {resources({ item: 'empresa_visualizar_colaborador' }) ? (
              <Tab label='Colaboradores' tabIndex={2} value={2} />
            ) : null}
            {resources({ item: 'empresa_visualizar_cartoes' }) ? <Tab label='Cartões' tabIndex={3} value={3} /> : null}
            {resources({ item: 'empresa_visualizar_beneficios' }) ? (
              <Tab
                label='Benefícios'
                sx={{ '&:after': { display: 'none' }, marginRight: '8px' }}
                tabIndex={4}
                value={4}
              />
            ) : null}
            <Box sx={{ margin: '0px 16px 0 auto', display: 'flex', gap: '16px', alignItems: 'center' }}>
              {migrationId && !isLoading && !isFetching && (
                <Tooltip title='Solucionar problemas' arrow={false}>
                  <Button
                    variant='contained'
                    color='error'
                    sx={{
                      height: '42px',
                      minHeight: '42px !important',
                      width: '42px',
                      minWidth: '42px',
                      padding: '8px',
                      borderRadius: '4px',
                      '&::after': { display: 'none' },
                    }}
                    onClick={() => setIsMigrationDialogOpen(true)}
                  >
                    <Icon icon={faExclamationCircle} />
                  </Button>
                </Tooltip>
              )}
              {value === 0 && !isLoading && (
                <StatusChip sx={{ height: '42px' }} status={statusColor} label={COMPANY_STATUS[status]} />
              )}
            </Box>
          </Tabs>

          <TabPanel value={value} index={0} sx={{ padding: '16px 16px 48px 16px' }}>
            {resources({ item: 'empresa_visualizar' }) ? <CompanyGeneralData /> : null}
          </TabPanel>

          <TabPanel value={value} index={1} sx={{ padding: '16px 16px 48px 16px' }}>
            {resources({ item: 'empresa_parametros_gerais' }) ? <Parameters /> : null}
          </TabPanel>

          <TabPanel value={value} index={2}>
            {resources({ item: 'empresa_visualizar_colaborador' }) ? <CompanyEmployees /> : null}
          </TabPanel>

          <TabPanel value={value} index={3}>
            {resources({ item: 'empresa_visualizar_cartoes' }) ? <CompanyCards /> : null}
          </TabPanel>

          <TabPanel value={value} index={4}>
            {resources({ item: 'empresa_visualizar_beneficios' }) ? <CompanyBenefits /> : null}
          </TabPanel>

          <MigrationErrorDialog
            open={isMigrationDialogOpen}
            error={{
              message: messageMigration,
              migrationId: migrationId,
              companyId: id,
            }}
            onClose={closeMigrationErrorDialog}
          />
        </>
      </CompanyContext.Provider>
    </Panel>
  )
}
