import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Box, Button } from '@stationkim/front-ui'
import { ButtonsContainer, StyledAccordion, StyledButton, StyledDivider } from './styles'
import { AdministrativeFee } from './components/administrativeFee/administrativeFee'
import {
  EAdministrativeFeeCalculationType,
  EAdministrativeFeeValueType,
  EBenefitType,
  ECompanyStatus,
  EDeskFeeType,
  EParameterStatus,
} from '@/main/enums'
import { CompanyContext } from '../../consultCompany'
import { IssFee } from './components/issFee/issFee'
import { ParameterDialogEdition } from './components/parameterDialogEdition/parameterDialogEdition'
import { useCompanyParametersMutation } from '../../hooks/useCompanyParametersMutation'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { SuccessFee } from './components/successFee/successFee'

export type TAdministrativeFee = {
  benefit: EBenefitType
  calculationType: EAdministrativeFeeCalculationType
  valueType: EAdministrativeFeeValueType
  initialRange: string
  finalRange: string
  feeValue: string
  id?: string
}

export type TCompanyParametersForm = {
  successFee: string
  issFee: boolean
  issIncidences: number[]
  administrativeFees: TAdministrativeFee[]
  newBalanceProjection: boolean
}

const initialValues: TCompanyParametersForm = {
  successFee: '',
  issFee: false,
  administrativeFees: [],
  issIncidences: [],
  newBalanceProjection: false,
}

export const Parameters = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const { company, isLoading, isFetching } = useContext(CompanyContext)
  const { editParameters } = useCompanyParametersMutation()
  //so pode ditar iss com empresa aprovada ou aguardando aprovacao
  const isIssAvailable = company?.issInfo?.status === EParameterStatus.Active
  const isWithMigrationError = company?.status === ECompanyStatus.MigrationError
  const isIssConfigurationAvailable = [ECompanyStatus.Approved, ECompanyStatus.WaitingApproval].includes(
    company?.status,
  )

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<TCompanyParametersForm>({
    defaultValues: initialValues,
  })

  const issFee = watch('issFee')

  const onSubmit: SubmitHandler<TCompanyParametersForm> = (data) => {
    if (!isConfirmationDialogOpen) setIsConfirmationDialogOpen(true)
    else
      editParameters.mutate(
        {
          companyId: company.id,
          fees: data.administrativeFees.map((fee) => ({
            id: company.administrativeFees.find(({ benefit }) => benefit === fee.benefit).id,
            benefitType: fee.benefit,
            calculationType: fee.calculationType,
            quantityType: fee.valueType,
            valueType: fee.valueType, //?, todo
            startRange: Number(fee.initialRange),
            endRange: Number(fee.finalRange),
            value: Number(fee.feeValue),
            itemType: EDeskFeeType.administrative,
          })),
          issActive: data.issFee,
          issFee: data?.issIncidences?.reduce((final, actual) => final + actual, 0) || 0,
          slipFee: 0, //endpoint requirement
          successFee: Number(data?.successFee || 0),
          newBalanceProjection: data.newBalanceProjection,
        },
        {
          onSuccess: () => {
            setIsConfirmationDialogOpen(false)
            queryClient.invalidateQueries({ queryKey: ['company'] })
          },
        },
      )
  }

  const handleCancel = () => {
    if (isDirty) reset()
    else navigate('/empresas')
  }

  const handleCancelParameterEdition = () => setIsConfirmationDialogOpen(false)
  const handleFormSave = handleSubmit(onSubmit)

  useEffect(() => {
    if (company)
      reset({
        ...initialValues,
        successFee: company?.successFee || '0',
        issFee: company.issInfo?.isActive,
        issIncidences: company.issInfo?.issIncidences,
        administrativeFees: company.administrativeFees as TAdministrativeFee[],
        newBalanceProjection: company.newBalanceProjection || false,
      })
  }, [company, reset])

  return (
    <Box>
      <form id='company-parameters-form' onSubmit={handleFormSave}>
        {isIssAvailable && (
          <>
            <IssFee
              issInfo={company.issInfo}
              isVisible={issFee}
              control={control}
              disabled={!isIssConfigurationAvailable}
              isLoading={isLoading}
            />
            <StyledDivider />
          </>
        )}
        <StyledAccordion defaultExpanded label='Taxas administrativas'>
          <AdministrativeFee control={control} disabled={isWithMigrationError} isLoading={isLoading || isFetching} />
        </StyledAccordion>
        <StyledDivider />
        <StyledAccordion defaultExpanded label='Taxa de sucesso'>
          <SuccessFee control={control} isLoading={isLoading} watch={watch} />
        </StyledAccordion>
        <StyledDivider />
        <ButtonsContainer sx={{ gap: isDirty ? '16px' : 0 }}>
          <Button variant='outlined' onClick={handleCancel}>
            {isDirty ? 'Cancelar' : 'Voltar'}
          </Button>
          <StyledButton visible={isDirty} variant='contained' type='submit' disabled={isLoading}>
            Salvar
          </StyledButton>
        </ButtonsContainer>
      </form>
      <ParameterDialogEdition
        open={isConfirmationDialogOpen}
        onCancel={handleCancelParameterEdition}
        onConfirm={handleFormSave}
        isLoading={editParameters.isLoading}
      />
    </Box>
  )
}
