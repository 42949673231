import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { ButtonSelector, ButtonSelectorOption } from '@stationkim/front-ui'
import { OldOrdersList } from './oldOrdersList/oldOrdersList'
import { ListOrders as NewOrderList } from './listOrders'

const lists = [NewOrderList, OldOrdersList]
const listSelectorAtom = atomWithHash('selectedList', 0)

export const ListOrders = () => {
  const [visibleList, setVisibleList] = useAtom(listSelectorAtom)
  const listSelector = (
    <ButtonSelector value={visibleList} onChange={({ targetIndex }) => setVisibleList(targetIndex)}>
      <ButtonSelectorOption>Broker</ButtonSelectorOption>
      <ButtonSelectorOption>Dealer</ButtonSelectorOption>
    </ButtonSelector>
  )
  const Component = lists[visibleList]
  return <Component listSelector={listSelector} />
}
