import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import api from '@/infra/api'
import { EStatusCompra, EStatusPedido, ETipoPagamento, ETipoPedido } from '@/main/enums'
import { ORDER_STATUS } from '@/main/utils/constants'
import { IFetchHook, OrderPaymentType } from '@/main/interfaces'
import {
  DetailsApiReturn,
  ItemsApiReturn,
  Response,
  SiblingsApiReturn,
  IUseOrderTrackingReturn,
  ItemsCardsApiReturn,
} from './utils/interfaces'
import { orderIdNewApiAtom, purchaseIdNewApiAtom } from '@/main/store'
import { IOrderItemsPaged } from '../utils/interfaces/iOrderItemsPaged'
import { IOrderItem } from '../utils/interfaces/iOrderItem'

// Need to equalize the actual status with the old api ones
const equalizeStatus = (orderStatus: EStatusPedido, purchaseStatus: EStatusCompra) => {
  if (purchaseStatus === EStatusCompra.AguardandoPagamento) return ORDER_STATUS.WAITING_PAYMENT
  if (purchaseStatus === EStatusCompra.ProcessandoFormaPagamento) return 99
  const convertedStatus = {
    [EStatusPedido.NoCarrinho]: ORDER_STATUS.DRAFT,
    [EStatusPedido.Efetivando]: ORDER_STATUS.EFFECTING,
    [EStatusPedido.Efetivado]: ORDER_STATUS.WAITING_PAYMENT,
    [EStatusPedido.AguardandoProcessamento]: ORDER_STATUS.WAITING_FOR_PROCESSING,
    [EStatusPedido.EmProcessamento]: ORDER_STATUS.PROCESSING,
    [EStatusPedido.EmAndamento]: ORDER_STATUS.RUNNING,
    [EStatusPedido.DisponivelRetirada]: ORDER_STATUS.AVAILABLE_PICKUP,
    [EStatusPedido.Entrega]: ORDER_STATUS.DELIVERING,
    [EStatusPedido.Finalizado]: ORDER_STATUS.FINISHED,
    [EStatusPedido.Cancelando]: ORDER_STATUS.CANCELING,
    [EStatusPedido.Cancelado]: ORDER_STATUS.CANCELED,
  }
  return convertedStatus[orderStatus]
}

const extractPaymentTypes = (payments: Response['pagamentoCompra']['pagamentos']) => {
  const statusMap = {
    [ETipoPagamento.Boleto]: 'SLIP',
    [ETipoPagamento.CarteiraOperadora]: 'TICKETING_BALANCE',
    [ETipoPagamento.CarteiraSK]: 'ACCOUNT_BALANCE',
    [ETipoPagamento.Gratuito]: 'FREE',
    [ETipoPagamento.Pix]: 'PIX',
  }
  return payments.map((payment) => statusMap[payment.tipo]) as OrderPaymentType[]
}

const getPayment = (paymentType: ETipoPagamento, payments: Response['pagamentoCompra']['pagamentos']) => {
  const payment = payments.find((pay) => pay.tipo === paymentType)
  return payment
}

export const useOrderTracking = ({ filter, pagination, immutableQuery }: IFetchHook): IUseOrderTrackingReturn => {
  const navigate = useNavigate()
  const purchaseId = useAtomValue(purchaseIdNewApiAtom)
  const orderId = useAtomValue(orderIdNewApiAtom)

  //main order data
  const { data, error, ...rest } = useQuery<AxiosResponse<DetailsApiReturn>>({
    queryKey: ['newOrderDetail', orderId, purchaseId],
    queryFn: () => api.get(`revenda-pedidos-bff/pedido/detalhamento?idPedido=${orderId}&idCompra=${purchaseId}`),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: orderId !== '',
  })

  // order items cards
  const {
    data: cardsData,
    isLoading: isCardsLoading,
    status: cardReqStatus,
  } = useQuery<AxiosResponse<ItemsCardsApiReturn[]>>({
    queryKey: ['newOrderDetailItemsCards', orderId, purchaseId, pagination, filter, immutableQuery],
    queryFn: () => api.get(`revenda-pedidos-bff/pedido/cartoes/${orderId}`),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: orderId !== '',
  })

  // order items
  const { data: itemsData, isLoading: isItemsLoading } = useQuery<AxiosResponse<ItemsApiReturn>>({
    queryKey: ['newOrderDetailItems', orderId, purchaseId, pagination, filter, immutableQuery],
    queryFn: () =>
      api.get(`revenda-pedidos-bff/pedido/detalhamento/itens?idPedido=${orderId}`, {
        params: { ...pagination, ...filter, ...immutableQuery, id: orderId },
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: orderId !== '' && cardReqStatus !== 'loading',
  })

  // sibling orders
  const { data: siblingData, isLoading: isLoadingSiblings } = useQuery<AxiosResponse<SiblingsApiReturn>>({
    queryKey: ['newOrderSiblingsOrders', purchaseId, pagination, filter, immutableQuery],
    queryFn: () =>
      api.get(`revenda-pedidos-bff/pedido/detalhamento/pedidos?idCompra=${purchaseId}`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: orderId !== '',
  })

  useEffect(() => {
    if (error) navigate('/pedidos')
  }, [error, navigate])

  const organizeItemsData = (): IOrderItemsPaged => {
    const rawItems = itemsData?.data?.valor?.itens
    const rawCards = cardsData?.data
    if (!rawItems || !rawCards) return {} as IOrderItemsPaged
    const items: IOrderItem[] = rawItems.map((item): IOrderItem => {
      const cardInfo = rawCards.find((rawCard) => String(rawCard.idItem) === String(item.id))
      return {
        benefitType: '1', // only recharges by now
        cardNumber: cardInfo?.numero, // item.numeroCartao,
        days: item.qtdDias,
        dayValue: item.valorDiario,
        employeeName: item.nomeColaborador,
        employeeRegistration: item.matriculaColaborador,
        operatorRegistration: item.matriculaNaOperadora,
        total: item.valorRecarga,
        employeeId: item.id,
        removed: item.colaboradoExcluido,
        employeeGuid: cardInfo?.guidColaborador,
      }
    })
    return {
      data: items,
      page: itemsData?.data?.valor?.pagina || 1,
      pageSize: pagination.qtdLinhas as any,
      totalItems: itemsData?.data?.valor?.qtdColaboradores,
      totalPages: itemsData?.data?.valor?.qtdPaginas,
    }
  }

  const organizeCartOrders = (actualOrderCode: number, purchaseStatus: number) => {
    const rawData = siblingData?.data?.valor
    if (!rawData) return []
    return rawData.reduce((final, sibling) => {
      if (sibling.codigo === actualOrderCode) return final
      return [
        ...final,
        {
          code: String(sibling.codigo),
          id: String(sibling.id),
          operatorName: sibling.nomeOperadora,
          status: equalizeStatus(sibling.status, purchaseStatus),
          totalItems: sibling.qtdItensUnicos,
          totalValue: sibling.valorTotal,
          type: sibling.tipo,
        },
      ]
    }, [])
  }

  const organizeData = (): IUseOrderTrackingReturn['order'] => {
    const rawData = data?.data?.valor
    if (!rawData) return null
    const orderStatus = equalizeStatus(rawData.pedidoDetalhado.status, rawData.statusCompra)
    const cartOrders = organizeCartOrders(rawData.pedidoDetalhado.codigo, rawData.statusCompra)
    const slip = getPayment(ETipoPagamento.Boleto, rawData.pagamentoCompra.pagamentos)
    const pix = getPayment(ETipoPagamento.Pix, rawData.pagamentoCompra.pagamentos)
    return {
      orderCode: String(rawData.pedidoDetalhado.codigo),
      orderId: orderId,
      orderDate: new Date(rawData.pedidoDetalhado.dataCriacao).toLocaleDateString(),
      cancelDate: rawData.pedidoDetalhado.dataCancelamento,
      orderOperatorName: rawData.pedidoDetalhado.nomeOperadora,
      orderStatus: orderStatus,
      status: orderStatus,
      orderType: ETipoPedido.RecargaVtBroker,
      removedItems: rawData.colaboradoresRemovidos,
      removedItemsReturnedValue: rawData.valorTotalEstornadoRemovidos,
      totalItems: 0, // Utilizado pela integraçao antiga
      usedPaymentsTypes: extractPaymentTypes(rawData.pagamentoCompra.pagamentos),
      operatorSlip: rawData.pedidoDetalhado.urlBoletoRevenda,
      orderSummary: {
        administrativeFee: rawData.pedidoDetalhado.pagamento.taxaAdministrativa,
        issFee: rawData.pedidoDetalhado.pagamento.retencaoIss,
        processingFee: 0, //remove
        transferFee: rawData.pedidoDetalhado.pagamento.taxaRepasse,
        walletUsage: 0, // Utilizado pela integraçao antiga
        orderValue: rawData.pedidoDetalhado.pagamento.valorRecarga,
        orderTotal: rawData.pedidoDetalhado.pagamento.valorTotal,
        totalPaid: rawData.pedidoDetalhado.pagamento.valorTotalPago,
        ticketingBalance: rawData.pedidoDetalhado.pagamento.valorUsoCarteiraOperadora,
      },
      cart: {
        hasFirstCopy: false, // vai ser utilizado no futuro
        hasSecondCopy: false, // vai ser utilizado no futuro
        hasRecharge: true, // vai ser utilizado no futuro
        cartOrders: cartOrders,
        purchaseId: purchaseId,
        cartSummary: {
          administrativeFee: rawData.pagamentoCompra.taxaAdministrativa,
          issFee: rawData.pagamentoCompra.retencaoIss,
          orderTotal: rawData.pagamentoCompra.valorTotal,
          processingFee: rawData.pagamentoCompra.taxaProcessamento,
          totalPaid: rawData.pagamentoCompra.valorTotalPago,
          transferFee: rawData.pagamentoCompra.taxaRepasse,
          ticketingBalance: rawData.pagamentoCompra.pagamentos.find(
            (payment) => payment.tipo === ETipoPagamento.CarteiraOperadora,
          )?.valor,
          walletUsage: rawData.pagamentoCompra.pagamentos.find((payment) => payment.tipo === ETipoPagamento.CarteiraSK)
            ?.valor,
          firstCopy: 0, // deve ser integrado no futuro
          secondCopy: 0, // deve ser integrado no futuro
          rechargeValue: rawData.pagamentoCompra.valorRecarga,
          totalInOrders: 0, //sem uso na nova integracao
        },
        code: String(rawData.codigoCompra),
        id: cartOrders.length > 0 ? 'purchase' : '',
      },
      slip: {
        barcodeNumber: slip?.codigo,
        slipDueDate: slip
          ? new Date(slip?.dataVencimento).toLocaleDateString()
          : pix
          ? new Date(pix?.dataVencimento).toLocaleDateString()
          : null,
        slipLink: rawData.pagamentoCompra.urlBoleto,
        value: slip?.valor,
      },
      pix: {
        qrCode: pix?.codigo,
        value: pix?.valor,
        orderDate: rawData.pedidoDetalhado.dataCriacao,
      },
      orderItems: organizeItemsData(),
    }
  }

  const organizedData = organizeData()

  return {
    order: organizedData,
    isItemsLoading: isCardsLoading || isItemsLoading,
    ...rest,
    isLoading: isLoadingSiblings || rest.isLoading,
  }
}
