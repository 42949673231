export * from './eBenefitType'
export * from './eProcessType'

export enum EAdministrativeFeeCalculationType {
  Order = 1,
  Employee,
}

export enum EAdministrativeFeeValueType {
  Fixed = 1,
  Percentual,
}

export enum EDeskFeeType {
  administrative = 1,
  delivery,
  balanceReuse,
}

export enum EBenefitType {
  Transport = 1,
  Food,
  Snack,
  Fuel,
  TransportPaper,
}

export enum EOrderPendency {
  None,
  Pending,
  Resolved,
}

export enum ECompanyStatus {
  Created = 0,
  WaitingApproval = 1,
  Approved = 2,
  Disapproved = 3,
  WaitingMigration = 4,
  Migrating = 5,
  MigrationError = 6,
  WaitingOperator = 7,
  Syncing = 8,
  SyncError = 9,
}

export enum EParameterStatus {
  Active = 1,
  Inactive,
}

export enum ETipoPendencia {
  Colaborador = 1,
  Pedido = 2,
}

export enum ETipoPagamento {
  Gratuito,
  Boleto,
  Pix,
  CarteiraSK,
  CarteiraOperadora,
}

export enum ETipoPedido {
  PrimeiraViaVtDealer = 1,
  SegundaViaVtDealer = 2,
  RecargaVtDealer = 3,
  Deposito = 4,
  RecargaVtBroker = 5,
  Compra = 6,
}

export enum EStatusPedido {
  NoCarrinho,
  Efetivando,
  Efetivado,
  AguardandoProcessamento,
  EmProcessamento,
  EmAndamento, //Processamento Operadora
  DisponivelRetirada,
  Entrega,
  Finalizado,
  Cancelando,
  Cancelado,
}

export enum EStatusCompra {
  Carrinho,
  Efetivando,
  ProcessandoFormaPagamento,
  AguardandoPagamento,
  EmAndamento,
  Finalizado,
  Cancelando,
  Cancelado,
}

export enum ETipoMotivoRemocaoRecarga {
  SinalizadoPeloCliente = 1,
  ReaproveitamentoSaldo = 2,
  CartaoNaoFicouPronto = 3,
}
