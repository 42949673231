import api from '@/infra/api'
import { useMutation } from '@tanstack/react-query'
import { ICompanyAPIFee } from './interfaces'

interface IParameterEditionPayload {
  issActive: boolean
  issFee: number
  fees: ICompanyAPIFee[]
  slipFee: number
  successFee: number
  companyId: string
  newBalanceProjection: boolean
}

export const useCompanyParametersMutation = () => {
  const editParameters = useMutation({
    mutationKey: ['companyParameterEdition'],
    mutationFn: ({ companyId, ...payload }: IParameterEditionPayload) =>
      api.put(`/companies-service/${companyId}/deskFees`, payload),
  })

  return {
    editParameters,
  }
}
