import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
interface IViaCepResponse {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

interface IUseAddressesInfos {
  config?: UseQueryOptions<any>
  cep: string | null
}

export const useAddressesInfos = (params: IUseAddressesInfos) => {
  const {
    cep,
    config = {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  } = params

  const { data: viaCep, isFetching: isLoadingViaCep } = useQuery<AxiosResponse<IViaCepResponse>>({
    queryKey: ['viacep', cep],
    queryFn: () => axios.get(`https://viacep.com.br/ws/${cep}/json/ `),
    enabled: cep?.length >= 8,
    ...config,
  })

  return {
    cepData: viaCep?.data,
    isLoading: isLoadingViaCep,
  }
}
