export const isCompositeText = ({ value }: { value: string }) => {
  const cleanedString = value.replace(/\s+/g, ' ').trim()
  const words = cleanedString.split(' ')
  if (words.length < 2) return false

  return true
}

export const isStringEquals = ({ value, compareTo }: { value: string; compareTo: string }) => {
  if (!value || !compareTo) return false
  return value.toLocaleLowerCase() === compareTo.toLocaleLowerCase()
}

export const isOnRange = ({ value, initial, final }: { value: number; initial: number; final: number }) => {
  if (value >= initial && value <= final) return true
  return false
}

export const isOnlyNumber = ({ value }: { value: string }) => {
  if (!value) return
  return Boolean(value.match(/[0-9]/gi))
}
