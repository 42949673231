import { validateCNPJ, validateCPF, validateEmail } from 'validations-br'
import {
  requiredMessage,
  maxLengthMessage,
  compositeNameMessage,
  rangeMessage,
  minLengthMessage,
  invalidMessage,
} from './messageGenerators'
import { isCompositeText, isOnRange, isOnlyNumber, isStringEquals } from './validationFunctions'

export const formFieldsConfiguration = {
  company: {
    cnpj: {
      label: 'CNPJ',
      placeholder: '00.000.000/0000-00',
      mask: [
        {
          mask: '00.000.000/0000-00',
          lazy: false,
          placeholderChar: '0',
        },
      ],
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        validate: {
          isValid: (value: string) => validateCNPJ(value) || 'CNPJ inválido',
        },
      }),
    },
    code: {
      label: 'Código da empresa',
      placeholder: '00000',
    },
    integrationCode: {
      label: 'Código da bilhetagem',
      placeholder: '00000',
    },
    corporateName: {
      label: 'Razão social',
      placeholder: '',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        maxLength: { value: 90, message: maxLengthMessage({ maxLength: 90 }) },
        validate: {
          isComposite: (value: string) =>
            isCompositeText({ value }) || compositeNameMessage({ fieldName: 'Razão social' }),
        },
      }),
    },
    tradeName: {
      label: 'Nome fantasia',
      placeholder: '',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        maxLength: { value: 40, message: maxLengthMessage({ maxLength: 40 }) },
        validate: {
          isComposite: (value: string) =>
            isCompositeText({ value }) || compositeNameMessage({ fieldName: 'Nome fantasia' }),
        },
      }),
    },
    stateRegistration: {
      label: 'Inscrição estadual',
      placeholder: '000000000',
      mask: [{ mask: '00000000000000' }],
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        maxLength: { value: 14, message: maxLengthMessage({ maxLength: 14 }) },
        validate: {
          isSizeCorrect: (value: string) => {
            const isInRangeAndOnlyNumbers =
              isOnRange({ value: value.length, initial: 9, final: 14 }) && isOnlyNumber({ value })
            if (isInRangeAndOnlyNumbers) return true
            else return rangeMessage({ fieldName: 'Inscrição estadual', initial: 9, final: 14 }) + ' numéricos'
          },
        },
      }),
    },
    municipalRegistration: {
      label: 'Inscrição municipal',
      placeholder: '000000000 ou Isento',
      rules: () => ({
        maxLength: { value: 11, message: maxLengthMessage({ maxLength: 11 }) },
        validate: {
          isSizeCorrect: (value: string) => {
            if (value?.length < 1 || !value) return true
            if (isOnlyNumber({ value }) || isStringEquals({ value, compareTo: 'isento' })) return true
            else return "Inscrição municipal deve conter 11 caracteres numéricos ou 'Isento'"
          },
        },
      }),
    },
    activityBranch: {
      label: 'Ramo da atividade',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
      }),
    },
    newBroker: {
      label: 'Novo Broker',
    },
  },
  address: {
    cep: {
      label: 'CEP',
      placeholder: '00000-000',
      mask: [
        {
          mask: '00000-000',
          lazy: false,
          placeholderChar: '0',
        },
      ],
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        minLength: { value: 8, message: minLengthMessage({ minLength: 8 }) },
      }),
    },
    laneName: {
      label: 'Endereço',
      placeholder: '',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        maxLength: { value: 120, message: maxLengthMessage({ maxLength: 120 }) },
        validate: {
          isComposite: (value: string) => isCompositeText({ value }) || compositeNameMessage({ fieldName: 'Endereço' }),
        },
      }),
    },
    number: {
      label: 'Número',
      placeholder: '00',
      mask: [{ mask: Number }],
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        maxLength: { value: 5, message: maxLengthMessage({ maxLength: 5 }) },
      }),
    },
    complement: {
      label: 'Complemento',
      placeholder: '',
      rules: () => ({
        maxLength: { value: 30, message: maxLengthMessage({ maxLength: 30 }) },
      }),
    },
    neighborhood: {
      label: 'Bairro',
      placeholder: '',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        maxLength: { value: 50, message: maxLengthMessage({ maxLength: 50 }) },
      }),
    },
    name: {
      label: 'Apelido do endereço',
      placeholder: '',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        maxLength: { value: 30, message: maxLengthMessage({ maxLength: 30 }) },
      }),
    },
    type: {
      label: 'Tipo de endereço',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
      }),
    },
  },
  contacts: {
    cpf: {
      label: 'CPF',
      placeholder: '000.000.000-00',
      mask: [
        {
          mask: '000.000.000-00',
          lazy: false,
          placeholderChar: '0',
        },
      ],
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        validate: {
          isValid: (value: string) => validateCPF(value) || 'CPF inválido',
        },
      }),
    },
    fullName: {
      label: 'Nome',
      placeholder: 'Nome completo',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        maxLength: { value: 130, message: maxLengthMessage({ maxLength: 130 }) },
        validate: {
          isComposite: (value: string) => isCompositeText({ value }) || compositeNameMessage({ fieldName: 'Endereço' }),
        },
      }),
    },
    email: {
      label: 'E-mail',
      placeholder: '',
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        validate: {
          isEmail: (value: string) => validateEmail(value) || invalidMessage({ fieldName: 'Endereço' }),
        },
      }),
    },
    phone: {
      label: 'Telefone',
      placeholder: '(00) 0000-0000',
      mask: [
        {
          mask: '(00) 0000-0000',
          lazy: false,
          placeholderChar: '0',
        },
        {
          mask: '(00) 00000-0000',
          lazy: false,
          placeholderChar: '0',
        },
      ],
      rules: ({ isRequired }: { isRequired: boolean }) => ({
        required: isRequired ? requiredMessage() : false,
        minLength: { value: 10, message: minLengthMessage({ minLength: 10 }) },
      }),
    },
    extension: {
      label: 'Ramal',
      placeholder: '000',
      mask: [
        {
          mask: Number,
        },
      ],
    },
  },
}
