import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { AxiosResponse } from 'axios'
import { companyIdAtom } from '@/main/store'
import { ICompanyAPIResponse, IUseCompany, IUseCompanyData } from './interfaces'
import { useMemo } from 'react'

const organizeData = (data: ICompanyAPIResponse): IUseCompanyData => {
  if (!data) return null
  return {
    activityBranch: data.activyBranch,
    activityBranchDescription: data.activyBranchDescription,
    addresses: [data.addressMain, ...data.addressDelivery],
    code: data.code,
    id: data.id,
    messageMigration: data.messageMigration,
    migrationId: data.migrationId,
    status: data.status,
    tradeName: data.tradeName,
    successFee: String(data.successFee),
    issInfo: !data.issInfo
      ? null
      : {
          id: data.issInfo.cityId,
          city: data.issInfo.customName,
          isActive: data.issInfo.active,
          value: String(data.issInfo.iss),
          status: data.issInfo.issStatus,
          issIncidences: data.issInfo.fees,
        },
    newBalanceProjection: data.newBalanceProjection,
    administrativeFees: data.administrateFees.map((fee) => ({
      benefit: fee.benefitType,
      calculationType: fee.calculationType,
      feeValue: String(fee.value),
      finalRange: String(fee.endRange),
      initialRange: String(fee.startRange),
      valueType: fee.quantityType,
      id: fee.id,
    })),
  }
}

export interface IUseCompanyReturn {
  company: IUseCompanyData
  isFetching: boolean
  isLoading: boolean
}

export const useCompany = (params: IUseCompany = {}): IUseCompanyReturn => {
  const { config = {} } = params
  const companyId = useAtomValue(companyIdAtom)
  const { data, ...rest } = useQuery<AxiosResponse<ICompanyAPIResponse>>({
    queryKey: ['company', companyId],
    queryFn: ({ queryKey }) => api.get(`resale-bff/companies/${queryKey[1]}`),
    ...config,
  })

  const company = useMemo(() => organizeData(data?.data), [data])

  return {
    company: company,
    isFetching: rest.isFetching,
    isLoading: rest.isLoading,
  }
}
