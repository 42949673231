import React, { useMemo, useState } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Box, Button, formatMoney, Grid, Icon, Skeleton } from '@stationkim/front-ui'
import { ADMINISTRATIVE_FEE_CALCULATION_TYPES, BENEFIT } from '@/main/utils/enumsMapping'
import { EAdministrativeFeeValueType } from '@/main/enums'
import { TAdministrativeFee, TCompanyParametersForm } from '../../parameters'
import { AddFeeDialog } from './components/addFeeDialog'
import { ButtonIcon } from './styles'

interface AdministrativeFeeProps {
  control: UseFormReturn<TCompanyParametersForm>['control']
  disabled?: boolean
  isLoading?: boolean
}

type TFeeToEdit = { fee: TAdministrativeFee; index: number }

export const AdministrativeFee = ({ control, disabled, isLoading }: AdministrativeFeeProps) => {
  const [isAddFeeDialogOpen, setIsAddFeeDialogOpen] = useState(false)
  const [feeToEdit, setFeeToEdit] = useState<TFeeToEdit | null>(null)

  const { append, fields, remove, update } = useFieldArray({
    control,
    name: 'administrativeFees',
  })

  const handleDialogClose = () => {
    setIsAddFeeDialogOpen(false)
    setFeeToEdit(null)
  }

  const sortedFilters = useMemo(() => {
    const fieldsWithOriginalIndex = fields.map((field, index) => ({ ...field, originalIndex: index }))
    return fieldsWithOriginalIndex.sort((a, b) => {
      if (a.benefit === b.benefit) {
        return Number(a.initialRange) - Number(b.initialRange)
      }
      return Number(a.benefit) - Number(b.benefit)
    })
  }, [fields])

  const renderFees = () => {
    if (isLoading)
      return Array(3)
        .fill(null)
        .map((_, index) => (
          <Skeleton
            key={'administrativeFee_loading_' + index}
            height='40px'
            variant='rounded'
            sx={{ '& + &': { marginTop: '16px' } }}
          />
        ))
    const fees = sortedFilters.map((field, index) => {
      const isPercentual = field.valueType === EAdministrativeFeeValueType.Percentual
      const value = formatMoney(Number(field.feeValue))
      return (
        <React.Fragment key={field.id}>
          <Grid item xs={2.5}>
            {BENEFIT[field.benefit]}
          </Grid>
          <Grid item xs={2.5}>
            {ADMINISTRATIVE_FEE_CALCULATION_TYPES[field.calculationType]}
          </Grid>
          <Grid item xs={2.5}>
            {field.initialRange} - {field.finalRange}
          </Grid>
          <Grid item xs={2.5}>
            {isPercentual ? `${value} %` : `R$ ${value}`}
          </Grid>
          <Grid item xs={2}>
            <ButtonIcon
              id={`administrativeFee_button_${index}_edit`}
              data-testid={`administrativeFee_button_${index}_edit`}
              disabled={disabled}
              color='primary'
              onClick={() => {
                setFeeToEdit({ fee: field, index: field.originalIndex })
                setIsAddFeeDialogOpen(true)
              }}
            >
              <Icon icon={faPencil} />
            </ButtonIcon>
            <ButtonIcon
              id={`administrativeFee_button_${index}_remove`}
              data-testid={`administrativeFee_button_${index}_remove`}
              disabled={disabled}
              color='error'
              onClick={() => remove(field.originalIndex)}
            >
              <Icon icon={faTrash} />
            </ButtonIcon>
          </Grid>
        </React.Fragment>
      )
    })
    return (
      <Box sx={{ overflow: 'auto' }}>
        <Grid container spacing='16px' sx={{ minWidth: '450px', padding: '2px 0' }}>
          <Grid item xs={2.5}>
            Benefício
          </Grid>
          <Grid item xs={2.5}>
            Cálculo
          </Grid>
          <Grid item xs={2.5}>
            Intervalo
          </Grid>
          <Grid item xs={2.5}>
            Valor
          </Grid>
          <Grid item xs={2}>
            Ação
          </Grid>
          {fees}
        </Grid>
      </Box>
    )
  }

  return (
    <>
      {renderFees()}
      <Button
        onClick={() => setIsAddFeeDialogOpen(true)}
        variant='softText'
        startIcon={<Icon icon={faPlus} />}
        sx={{ marginTop: '32px' }}
        disabled={disabled || isLoading}
      >
        Adicionar taxa
      </Button>

      {isAddFeeDialogOpen && (
        <AddFeeDialog
          open={isAddFeeDialogOpen}
          defaultValues={feeToEdit?.fee}
          existentFees={fields}
          onClose={handleDialogClose}
          onConfirm={(data) => {
            if (feeToEdit) update(feeToEdit.index, data)
            else append(data)
            handleDialogClose()
          }}
        />
      )}
    </>
  )
}
