import { ETipoPedido } from '@/main/enums'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons'
import { Theme, useTheme } from '@mui/material'
import { Box, Icon, Tooltip, Typography } from '@stationkim/front-ui'
import { useMemo } from 'react'

interface OrderTypeProps {
  type: ETipoPedido
  noLabel?: boolean
}

const getTypeStyle = (theme: Theme, type: ETipoPedido) => {
  const cardBaseInfo = {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.dark + '0D',
    icon: faCreditCard,
  }

  const rechargeBaseInfo = {
    color: theme.palette.benefits.transport.dark,
    backgroundColor: theme.palette.benefits.transport.dark + '0D',
    icon: faMoneyBillTransfer,
  }

  const typeMap = {
    [ETipoPedido.PrimeiraViaVtDealer]: { ...cardBaseInfo, title: 'Cartão 1ª VIA' },
    [ETipoPedido.SegundaViaVtDealer]: { ...cardBaseInfo, title: 'Cartão 2ª VIA' },
    [ETipoPedido.RecargaVtBroker]: { ...rechargeBaseInfo, title: 'Recarga (Broker)' },
    [ETipoPedido.RecargaVtDealer]: { ...rechargeBaseInfo, title: 'Recarga (Dealer)' },
    [ETipoPedido.Compra]: { ...rechargeBaseInfo, title: 'Compra' },
    [ETipoPedido.Deposito]: { ...rechargeBaseInfo, title: 'Deposito' },
  }

  return typeMap[type]
}

export const OrderType = ({ type, noLabel }: OrderTypeProps) => {
  const theme = useTheme()
  const typeStyle = useMemo(() => getTypeStyle(theme, type), [theme, type])

  return (
    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', maxWidth: '100%' }}>
      <Tooltip title={noLabel && typeStyle.title} placement='top'>
        <Box
          sx={{
            backgroundColor: typeStyle.backgroundColor,
            color: typeStyle.color,
            borderRadius: '50%',
            minHeight: '24px',
            minWidth: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
          }}
        >
          <Icon icon={typeStyle.icon} />
        </Box>
      </Tooltip>
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          fontWeight: '600',
          fontSize: '.875rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {!noLabel && typeStyle.title}
      </Typography>
    </Box>
  )
}
