import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { companyIdAtom } from '../store/url/shared'
import { AxiosResponse } from 'axios'

interface ICompanyAddress {
  id: string
  cep: string
  state: string
  city: string
  cityCode: number
  neighborhood: string
  laneName: string
  laneTypeCode: number
  number: string
  complement: string
  type: string
  name: string
}

interface ICompanyContact {
  cpf: string
  id: string
  type: number
  fullName: string
  email: string
  phone: string
  extension: string
  contactType: string
  isMain: boolean
}

interface ICompanyDocument {
  id: string
  documentType: number
  documentTypeName: string
  fileName: string
  fileSize: string
  fileLink: string
}

interface ICompanyFee {
  id: string
  benefitType: number
  calculationType: number
  quantityType: number
  valueType: number
  value: number
  startRange: number
  endRange: number
}

interface ICompanyOperatorCard {
  id: string
  operatorId: string
  operatorName: string
  operatorCardId: string
  operatorCardName: string
  enable: boolean
  processType: number
}

interface ICompanyBenefits {
  isTransport: boolean
  isTransportPaper: boolean
  isFood: boolean
  isSnack: boolean
  isFuel: boolean
}

interface ICompanyFetchResponse {
  id: string
  code: number
  cnpj: string
  corporateName: string
  tradeName: string
  activyBranch: number
  municipalRegistration: string
  stateRegistration: string
  activyBranchDescription: string
  lastOrderDate: string
  creationDate: string
  numberBeneficiaries: number
  employeesCount: number
  active: boolean
  status: number
  benefits: ICompanyBenefits
  addressMain: ICompanyAddress
  isCompanyDeskFee: boolean
  defaultDepartmentId: string
  allowDefaultDepartmentOrder: boolean
  addressDelivery: Array<ICompanyAddress>
  contacts: Array<ICompanyContact>
  documents: Array<ICompanyDocument>
  operatorsCard: Array<ICompanyOperatorCard>
  administrateFees: Array<ICompanyFee>
  deliveryFees: Array<ICompanyFee>
  reuseFees: Array<ICompanyFee>
  benefitTypes: null
  calculationTypes: null
  quantityTypes: null
  valueTypes: null
  issInfo: object
  slipFee: number
  successFee: number
  cei: string
  messageMigration: null | string
  migrationId: null | string
  profile: string
  profileDescription: string
  newBroker: boolean
}

export interface IUseCompanyData
  extends Omit<ICompanyFetchResponse, 'activyBranch' | 'activyBranchDescription' | 'addressMain' | 'addressDelivery'> {
  activityBranch: number
  activityBranchDescription: string
  addresses: Array<ICompanyAddress>
}

interface IUseCompany {
  config?: object
}

const organizeData = (data: ICompanyFetchResponse) => {
  if (!data) return data
  const dataToReturn = {
    ...data,
    activityBranch: data.activyBranch,
    activityBranchDescription: data.activyBranchDescription,
    addresses: [data.addressMain], //[data.addressMain, ...data.addressDelivery],
  }
  delete dataToReturn.activyBranch
  delete dataToReturn.activyBranchDescription
  delete dataToReturn.addressMain
  delete dataToReturn.addressDelivery

  for (const key in dataToReturn) {
    dataToReturn[key] = dataToReturn[key] ?? ''
  }
  return dataToReturn
}

export const useCompany = (params: IUseCompany = {}) => {
  const { config = {} } = params
  const companyId = useAtomValue(companyIdAtom)
  const { data, ...rest } = useQuery<AxiosResponse<ICompanyFetchResponse>>({
    queryKey: ['company', companyId],
    queryFn: ({ queryKey }) => api.get(`resale-bff/companies/${queryKey[1]}`),
    ...config,
  })

  const company = organizeData(data?.data)
  return {
    company: company || ({} as IUseCompanyData),
    ...rest,
  }
}
