import Modal from 'react-responsive-modal'

import { Container, ContainerActions, ContainerPlan, Description, Header, Plan, SelectPlan, Topics } from './styles'
import { CloseIcon } from './assets/closeIcon'
import { Button } from '@stationkim/front-ui'
import { AccountPersistentStore } from '@/main/store'
import { useAtomWithStorageObserver } from '@/main/hooks'
import { useState } from 'react'
import { planIcon } from '../utils/planIcon'
import { usePlan } from './hooks/usePlans'
import { SelectedIcon } from './assets/selectedIcon'

interface ModalPlansProps {
  open: boolean
  onClose: () => void
}

export const ModalPlans: React.FC<ModalPlansProps> = ({ open, onClose }) => {
  const { selectPlan } = usePlan({ onClose })

  const [active, setActive] = useState('')

  const [account] = useAtomWithStorageObserver({
    atomWithStorage: AccountPersistentStore,
    storageName: 'account',
  })

  const plans = account?.resale?.availablePlans

  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseIcon={false}
      styles={{ modal: { background: 'transparent', boxShadow: 'none' } }}
    >
      <Container>
        <Header>
          <p>Selecione o plano da empresa</p>
          <CloseIcon onClose={onClose} />
        </Header>
        <Description>Selecione o plano de funcionalidades que será disponibilizado para essa empresa.</Description>
        <ContainerPlan>
          {plans?.map((plan) => (
            <Plan key={plan.id} active={active === plan?.profileName}>
              {planIcon({ plan: plan.description })}
              <p>{plan.description}</p>
              <span>Plano ideal para:</span>
              <Topics>
                {plan.topicsAccess?.map((topic) => (
                  <li key={topic}>{topic}</li>
                ))}
              </Topics>
              <SelectPlan
                active={active === plan?.profileName}
                variant='outlined'
                onClick={() => (active === plan?.profileName ? setActive('') : setActive(plan?.profileName))}
              >
                {active === plan.profileName ? <SelectedIcon /> : 'Selecionar'}
              </SelectPlan>
            </Plan>
          ))}
        </ContainerPlan>
        <ContainerActions>
          <Button variant='outlined' onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button variant='contained' onClick={() => selectPlan({ NameProfile: active })}>
            Confirmar
          </Button>
        </ContainerActions>
      </Container>
    </Modal>
  )
}
